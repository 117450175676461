import React from "react";
import { withRouter } from 'react-router-dom';
import { Formik } from "formik";
import FormHelper from "../../helpers/FormHelper";
import EventRegistration from "../../models/event/EventRegistration";
import { LoaderComponent } from "../basics/layout";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { EventRegistrationRepository, EventRepository } from "../../repository";
import { FormRequiredError } from "../errors";
import FormToastComponent from "../basics/FormToastComponent";
import EventFormError from "./errors/EventFormError";
import ArrayHelper from "../../helpers/ArrayHelper";

class EventRegistrationEditFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let model = this.props.registration !== undefined ? this.props.registration : new EventRegistration()

        this.state = {
            model: model,
            max_days: model.period.nr_days,
            loading: true,
            allEvents: [],
            events: [],
            periods: [],
            form_errors: []
        };

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        Promise.all([EventRepository.all()]).then(result => this.setState({
            allEvents: result[0],
            events: result[0]?.filter(item => item.active).map(item => {
                return { value: item.id, label: item.contents[0].title };
            }),
            periods: this.state.model.event.periods.filter(item => item.active).map(item => {
                return { value: item.id, label: item.contents[0].title, nr_days: item.nr_days, max_days: item.nr_days };
            }),
            loading: false
        }));
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <div className="custom-form website-setting">
                <Formik
                    enableReinitialize
                    validationSchema={EventRegistration.schema_edit(this.state.model, { events: this.state.events, periods: this.state.periods })}
                    initialValues={this.state.model}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, formikActions) => {
                        EventRegistrationRepository.update({ id: values.id, client: values.client.id, event: values.event.id, period: values.period.id, nr_days: values.nr_days })
                            .then(result => {
                                FormToastComponent.successTrans('Settings', 'Default.saved');

                                let eventId = parseInt(this.props.match.params.event);
                                let periodId = parseInt(this.props.match.params.period);

                                let newRows = [];
                                this.props.parent_ref.state.rows.forEach(row => {
                                    if (row.id === result.id) {
                                        row = result;
                                    }

                                    if (
                                        (eventId && eventId !== row.event.id) ||
                                        (periodId && periodId !== row.period.id)
                                    ) {
                                        return;
                                    }
                                    newRows.push(row);
                                });

                                this.props.parent_ref.setState({ rows: newRows, showEditModal: false, editRegistration: null });
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <FormattedMessage id="CMS.Event.registrations.edit.form.event">
                                        {(value) => <Form.Label>{value}</Form.Label>}
                                    </FormattedMessage>
                                    <Select name="event" className="react-select-container" classNamePrefix="react-select"
                                        placeholder={<FormattedMessage id="CMS.Form.select.placeholder" />}
                                        isInvalid={!!errors?.event || !!this.state.form_errors.event}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            // handleChange({ target: { type: 'number', name: 'nr_days', value: e.nr_days ? e.nr_days : this.state.model.nr_days } })
                                            handleChange({ target: { type: 'select', name: 'event', value: { id: e.value } } });

                                            let selectedEvent = this.state.allEvents.filter(event => event.id === parseInt(e.value))[0];
                                            if (selectedEvent) {
                                                let model = this.state.model;
                                                model.event = selectedEvent;
                                                model.period = selectedEvent.firstPeriod();
                                                model.nr_days = model.period.nr_days;

                                                this.setState({
                                                    model: model,
                                                    periods: selectedEvent.periods.map(item => {
                                                        return { value: item.id, label: item.contents[0].title, nr_days: item.nr_days, max_days: item.nr_days };
                                                    })
                                                }, () => handleChange({ target: { type: 'select', name: 'period', value: { id: model.period?.id } } }));
                                            }
                                        }}
                                        value={this.state.events.find(e => e.value === values.event?.id)}
                                        options={ArrayHelper.sortByValue(this.state.events, 'label')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.event && touched.event && <FormRequiredError field_name="event" />}
                                        {this.state.form_errors.name && <p>{this.state.form_errors.name}</p>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {this.state.periods && (
                                    <Form.Group>
                                        <FormattedMessage id="CMS.Event.registrations.edit.form.period">
                                            {(value) => <Form.Label>{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Select name="period" className="react-select-container" classNamePrefix="react-select"
                                            placeholder={<FormattedMessage id="CMS.Form.select.placeholder" />}
                                            isInvalid={!!errors?.event || !!this.state.form_errors.period}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                this.setState({ max_days: e.max_days }, () => {
                                                    handleChange({ target: { type: 'number', name: 'nr_days', value: e.nr_days ? e.nr_days : this.state.model.period.nr_days } })
                                                    handleChange({ target: { type: 'select', name: 'period', value: { id: e.value } } });

                                                    let model = this.state.model;
                                                    model.period = this.state.model.event.getPeriod(parseInt(e.value));
                                                    model.nr_days = model.period.calculateNrDays();

                                                    this.setState({ model: model });
                                                })
                                            }}
                                            value={this.state.periods.find(e => e.value === values.period?.id)}
                                            options={this.state.periods}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.event && touched.event && <FormRequiredError field_name="period" />}
                                            {this.state.form_errors.period && <p>{this.state.form_errors.period}</p>}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                                <Form.Group>
                                    <FormattedMessage id="CMS.Event.registrations.edit.form.nr_days">
                                        {(value) => <Form.Label>{value}</Form.Label>}
                                    </FormattedMessage>
                                    <Form.Control type="number" name="nr_days" min='1' max={this.state.max_days}
                                        value={values.nr_days || 1}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nr_days || !!this.state.form_errors.nr_days}
                                        onBlur={handleBlur}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nr_days && touched.nr_days && <EventFormError error={errors.nr_days} />}
                                        {this.state.form_errors.nr_days && <p>{this.state.form_errors.nr_days}</p>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Row className="buttons">
                                    <Col xs={6}>
                                        <Form.Group>
                                            <FormattedMessage id="CMS.Event.registrations.edit.form.save">
                                                {value => (
                                                    <Button type={"submit"} onClick={() => {
                                                        FormHelper.submitFormErrorToast(this);
                                                        this.setState({ form_errors: [] })
                                                    }}>
                                                        {value}
                                                    </Button>
                                                )}
                                            </FormattedMessage>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <FormattedMessage id="CMS.Websites.Translation.Form.cancel">
                                                {value => (
                                                    <Button variant="secondary" onClick={() => this.props.parent_ref.setState({ showEditModal: false, editRegistration: null })}>
                                                        {value}
                                                    </Button>
                                                )}
                                            </FormattedMessage>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EventRegistrationEditFormComponent);